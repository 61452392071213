<template>
  <ppt-attr-panel title="阴影">
    <div class="dsf-ppt-attrs-row">
      <dsf-ppt-color-select :value="shadowColor" @input="$attr.setAttrsByStr(vm, 'shadowColor', $event)" />
    </div>
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">偏移</div>
      <label class="dsf-ppt-attrs-row__column">
        <span>X</span>
        <el-input :value="shadowOffsetX" size="mini" @input="$attr.setAttrs(vm, 'shadowOffsetX', $event)" />
      </label>
      <label class="dsf-ppt-attrs-row__column">
        <span>Y</span>
        <el-input :value="shadowOffsetY" size="mini" @input="$attr.setAttrs(vm, 'shadowOffsetY', $event)" />
      </label>
    </div>
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">扩散</div>
      <label class="dsf-ppt-attrs-row__column">
        <el-input :value="shadowBlur" size="mini" @input="$attr.setAttrs(vm, 'shadowBlur', $event)" />
      </label>
    </div>
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">透明</div>
      <label class="dsf-ppt-attrs-row__column">
        <el-slider :value="shadowOpacity" :min="0" :max="1" :step="0.000001" size="mini" @input="$attr.setAttrs(vm, 'shadowOpacity', $event)" />
      </label>
    </div>
  </ppt-attr-panel>
</template>

<script>
import PptAttrPanel from './PptAttrPanel';

export default {
  name: "ShadowAttrsItem",
  inject: ['$attr'],
  components: {
    PptAttrPanel
  },
  data() {
    return {
      vm: this,
      shadowColor: '#fff',
      shadowBlur: 0,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowOpacity: 1
    }
  },
  methods: {
    updateAttrs() {
      this.$attr.getAttr(['shadowColor', 'shadowBlur', 'shadowOffsetX', 'shadowOffsetY', 'shadowOpacity'], this);
    }
  }
}
</script>