<template>
  <el-dialog
    :visible.sync="show"
    :show-close="false"
    :close-on-click-modal="false"
    width="400px"
    title="配置"
    custom-class="dsf-ppt-config"
    modal-append-to-body
    append-to-body>
    <el-form :model="form" size="small" label-width="100px">
      <el-form-item label="图片宽(px)">
        <el-input-number v-model="form.width" :disabled="$ppt.propsDisabledConfig" :min="10" :max="4096" :controls="false" step-strictly />
      </el-form-item>
      <el-form-item label="图片高(px)">
        <el-input-number v-model="form.height" :disabled="$ppt.propsDisabledConfig" :min="10" :max="4096" :controls="false" step-strictly />
      </el-form-item>
      <el-form-item label="导出格式">
        <el-select v-model="form.mimeType" :disabled="$ppt.propsDisabledConfig" placeholder="请选择">
          <el-option label="PNG" value="image/png" />
          <el-option label="JPEG" value="image/jpeg" />
        </el-select>
      </el-form-item>
      <el-form-item label="导出质量">
        <el-input-number v-model="form.quality" :disabled="$ppt.propsDisabledConfig" :min="0" :max="1" :step="0.01" :precision="2" :controls="false" step-strictly />
      </el-form-item>
    </el-form>
    <div slot="footer">
      <dsf-button v-if="!callback" size="small" type="plain" @click="cancel">取 消</dsf-button>
      <dsf-button v-if="!$ppt.propsDisabledConfig" size="small" @click="confirm">确 定</dsf-button>
    </div>
  </el-dialog>
</template>

<script>
const defaultConfig = {
  width: 800,
  height: 600,
  mimeType: 'image/png',
  quality: 0.85
};
const keyList = ['width', 'height', 'mimeType', 'quality'];

export default {
  name: "PptConfig",
  inject: ['$ppt'],
  data() {
    return {
      show: false,
      form: {
        width: 800,
        height: 600,
        mimeType: 'image/png',
        quality: 0.85
      },
      callback: null
    }
  },
  methods: {
    open(callback = null) {
      let config = this.$ppt.project;
      keyList.forEach(k => {
        this.form[k] = config[k] || defaultConfig[k];
      });
      this.show = true;
      this.callback = callback;
    },
    confirm() {
      this.show = false;
      let form = this.form;
      let config = this.$ppt.project;
      let rectChanged = config['width'] !== form['width'] || config['height'] !== form['height'];
      keyList.forEach(k => {
        this.$ppt.$set(config, k, form[k]);
      });
      if (this.callback) {
        this.callback();
        this.callback = null;
      } else if (rectChanged) {
        this.$ppt.updateRect();
      }
    },
    cancel() {
      this.show = false;
      this.callback = null;
    }
  }
}
</script>