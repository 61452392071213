import html2canvas from 'html2canvas';
import PptQuillEditor from './PptQuillEditor.js';

const defaultHtml = '<p>Hollow DSF</p>';

export default function () {
  class RichText extends window.Konva.Image{
    constructor(config) {
      super(config);
      this.on('dblclick', this.#dblclick);
      this.on('transformend', async () => {
        await this.htmlToImage();
      });
      if (typeof config.html === 'string') {
        this.attrs.html = config.html;
      } else {
        this.attrs.html = defaultHtml;
      }
    }

    html(str) {
      if (str === void 0) {
        return this.attrs.html;
      } else {
        this.attrs.html = str;
        return this.htmlToImage();
      }
    }

    async htmlToImage() {
      let stage = this.getStage();
      stage.reading = true;
      let div1 = document.createElement('div');
      let div2 = document.createElement('div');
      div1.style.width = '0';
      div1.style.height = '0';
      div1.style.overflow = 'hidden';
      div1.style.position = 'absolute';
      div2.style.width = this.width() + 'px';
      div2.classList.add('ql-container', 'ql-bubble', 'dsf-ppt-konva-quill');
      div2.innerHTML = `<div class="ql-editor" style="padding-top: 6px">${this.attrs.html}</div>`;
      let transform = [];
      let scaleX = this.scaleX();
      if (scaleX) {
        transform.push(`scaleX(${scaleX})`);
      }
      let scaleY = this.scaleY();
      if (scaleY) {
        transform.push(`scaleY(${scaleY})`);
      }
      div2.style.transform = transform.join(' ');
      div1.appendChild(div2);
      document.body.appendChild(div1);
      div2.style.height = div2.firstChild.scrollHeight + 'px';
      this.height(div2.firstChild.scrollHeight);
      let canvas = await html2canvas(div2, {
        backgroundColor: 'rgba(0,0,0,0)'
      });
      this.image(canvas);
      div1.remove();
      stage.reading = false;
    }

    #dblclick() {
      _.each(this.getStage().find('Transformer'), t => t.destroy());
      this.hide();
      new PptQuillEditor(this);
    }

    _init() {
      this.htmlToImage();
    }
  }

  RichText.prototype.className = 'RichText';
  window.Konva['RichText'] = RichText;
}