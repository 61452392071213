import registerRichText from './RichText';
import registerIText from './IText';

export default () => {
  let Konva = window.Konva;
  if (Konva['IText']) {
    return;
  }

  let Container = Konva.Container;
  let Node = Konva.Node;
  let Util = Konva.Util;
  let OldStage = Konva.Stage;
  // 节点序列化时保留图片链接
  Node.prototype.toObject = function () {
    let obj = {},
      attrs = this.getAttrs(),
      key,
      val,
      getter,
      defaultValue,
      nonPlainObject;

    obj.attrs = {};
    obj.className = this.getClassName();

    for (key in attrs) {
      let imgSrc = null;
      val = attrs[key];
      nonPlainObject = Util.isObject(val) && !Util._isPlainObject(val) && !Util._isArray(val);
      if (nonPlainObject) {
        if (!val.src) {
          continue;
        }
        imgSrc = val.src;
      }
      getter = typeof this[key] === 'function' && this[key];
      delete attrs[key];
      defaultValue = getter ? getter.call(this) : null;
      attrs[key] = val;
      if (imgSrc) val = imgSrc;
      if (key === 'filters') {
        val = _.map(val, 'name');
      }
      if (defaultValue !== val) {
        obj.attrs[key] = val;
      }
    }
    if (obj.className === 'RichText') {
      obj.attrs.width = attrs.width;
    }

    return Util._prepareToStringify(obj);
  }
  // 容器序列化时去掉Transformer数据
  let _toObject = Container.prototype.toObject;
  Container.prototype.toObject = function () {
    let obj = _toObject.call(this);
    if (obj.children) {
      obj.children = obj.children.filter(child => child.className !== 'Transformer');
    }
    return obj;
  }
  // 添加init事件
  let add = Container.prototype.add;
  Container.prototype.add = function (...children) {
    add.apply(this, children);
    if (this instanceof window.Konva.Stage || this.getStage()) {
      children.forEach(c => c._init?.());
    }
    return this;
  }
  Container.prototype._init = function () {
    this.children.forEach(c => c._init?.());
  };
  // 反序列化时，处理图片信息
  let _createNode = Node._createNode;
  Node._createNode = function(obj, container) {
    let image = obj.attrs.image;
    let filters = obj.attrs.filters;
    let fillPatternImage = obj.attrs.fillPatternImage;
    let no = _createNode(obj, container);
    if (dsf.isString(image)) {
      delete no.attrs.image;
      Util._urlToImage(image, function(obj) {
        no.attrs.image = obj;
        no.getStage().draw();
      });
    }
    if (filters && filters.length) {
      no.cache();
      no.filters(_.map(filters, f => Konva.Filters[f]));
    }
    if (dsf.isString(fillPatternImage)) {
      delete no.attrs.fillPatternImage;
      Util._urlToImage(fillPatternImage, function(obj) {
        no.attrs.fillPatternImage = obj;
        no.getStage().draw();
      });
    }
    return no;
  }
  // 富文本在初始化时是异步的，所以也要把这个方法变为异步的
  Konva.Stage = class Stage extends OldStage{
    // 队列
    _queue = [];

    #reading = [];
    get reading() {
      return !!this.#reading.length;
    }
    set reading(val) {
      if (val !== false) {
        this.#reading.push(true);
      } else if (this.#reading.length) {
        this.#reading.pop();
        if (!this.#reading.length) {
          this._queue.forEach(q => q());
          this._queue = [];
        }
      }
    }

    constructor(...args) {
      super(...args);
    }

    asyncToDataURL(...args) {
      return new Promise((resolve, reject) => {
        if (!this.reading) {
          try {
            resolve(this.toDataURL.apply(this, args));
          } catch (e) {
            reject(e);
          }
        } else {
          this._queue.push(() => {
            try {
              resolve(this.toDataURL.apply(this, args));
            } catch (e) {
              reject(e);
            }
          });
        }
      });
    }
  }

  registerRichText();
  registerIText();
}