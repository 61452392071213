import DsfPptsSetting from "../control/DsfPptsSetting";
import DsfPptSetting from "../control/DsfPptSetting";

export default [
  {
    path: "/ppts/setting",
    name: "pptsSetting",
    meta: {
      title: "幻灯片设计"
    },
    component: DsfPptsSetting
  },
  {
    path: "/ppt/setting",
    name: "pptSetting",
    meta: {
      title: "幻灯片设计"
    },
    component: DsfPptSetting
  },
];