<template>
  <div class="dsf-ppt-tool-section dsf-ppt-layer-tree">
    <div class="dsf-ppt-tool-title">图层</div>
    <dsf-virtual-scroll height="0">
      <el-tree
        ref="tree"
        :data="treeData"
        :expand-on-click-node="false"
        :allow-drop="allowDrop"
        node-key="_id"
        highlight-current
        check-on-click-node
        default-expand-all
        draggable
        @current-change="currentChange"
        @node-drop="nodeDrop"
      >
        <template #default="{ node, data }">
          <div
            v-if="data.className !== 'Transformer' && data.attrs.id !== 'bg' ? true : (node.visible = false)"
            v-context-menu="{params: data, data: treeMenu }"
            class="custom-tree-node">
            <template v-if="data.children !== undefined">
              <i class="iconfont" :class="[node.expanded ? 'icon-dakai' : 'icon-wenjianjia']" />
              <span>{{ data.className }}</span>
            </template>
            <template v-else>
              <i class="iconfont" :class="[iconConfig[data.className]]" />
              <span>{{ nameConfig[data.className] }} <i class="node-id">{{data._id}}</i></span>
            </template>
          </div>
        </template>
      </el-tree>
    </dsf-virtual-scroll>
  </div>
</template>

<script>
export default {
  name: "PptLayerTree",
  inject: ['$ppt', '$pptMain'],
  data() {
    return {
      iconConfig,
      nameConfig,
      treeMenu: [
        { icon: 'icon-delete', name: '复制图层', handler: this.copyHandler },
        { icon: 'icon-delete', name: '删除图层', handler: this.removeHandler },
        { icon: 'icon-delete', name: '清除所有', handler: this.removeAllHandler },
      ]
    }
  },
  computed: {
    layer() {
      return this.$ppt.activeImg.layer;
    },
    treeData() {
      return this.layer?.children || [];
    },
    activeShape() {
      return this.$pptMain.activeShape;
    }
  },
  watch: {
    'layer': {
      handler(to) {
        if (!to) return;
      }
    },
    'activeShape' (to) {
      this.$refs.tree.setCurrentKey(to ? to._id : null);
    }
  },
  methods: {
    currentChange(item) {
      this.$ppt.konva.fire('click', {
        target: item
      });
    },
    // 判断拖拽时节点是否允许被放置
    allowDrop(draggingNode, dropNode, type) {
      return type !== 'inner';
    },
    // 拖拽完成
    nodeDrop(node, pNode, type, e) {
      this.layer.draw();
      if (this.activeShape?._id === node.data._id) {
        this.$refs.tree.setCurrentKey(node.data._id);
      }
    },
    // 复制图层
    copyHandler({ params }) {
      this.$ppt.copyNode = params.clone();
      dsf.layer.message('已复制到粘贴板');
    },
    // 删除图层
    removeHandler({ params }) {
      if (this.activeShape === params) {
        this.$ppt.konva.fire('click', {
          target: null
        });
        setTimeout(() => {
          params.destroy();
        }, 100);
      } else {
        params.destroy();
      }
    },
    // 删除所有图层
    removeAllHandler() {
      let bgDom = this.layer.findOne('#bg').clone();
      this.layer.destroyChildren();
      this.layer.add(bgDom);
    }
  }
}

const iconConfig = {
  'IText': 'icon-xingzhuang-wenzi',
  'RichText': 'icon-wenben',
  'Image': 'icon-tupian',
  'Rect': 'icon-xingzhuang-juxing',
  'Circle': 'icon-xingzhuang-tuoyuanxing',
  'Ellipse': 'icon-xingzhuang-tuoyuanxing',
  'triangle': 'icon-xingzhuang-sanjiaoxing',
  'polygon': 'icon-xingzhuang-xingxing',
  'line': 'icon-xian',
  'polyline': 'icon-zuzhizhankai',
}

const nameConfig = {
  'IText': '文本',
  'RichText': '富文本',
  'Image': '图片',
  'Rect': '矩形',
  'Circle': '圆形',
  'Ellipse': '椭圆',
  'triangle': '三角形',
  'polygon': '多边形',
  'line': '线',
  'polyline': '折线',
}
</script>