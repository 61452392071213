<template>
  <transition name="el-fade-in-linear">
    <div v-if="ready" class="ds-control dsf-ppt">
      <ppt-header />
      <section class="dsf-ppt-section">
        <ppt-main v-if="!isDesign && activeImg" ref="main" />
      </section>
      <ppt-footer />
      <ppt-config v-if="!isDesign" ref="config" />
      <ppt-metadata v-if="!isDesign && metadataList.length" ref="metadata"/>
    </div>
  </transition>
</template>

<script>
import mixins from './mixins';
import utils from '../utils/utils';

export default dsf.component({
  name: "DsfPptSetting",
  ctrlCaption: "图片设计",
  mixins: [$mixins.control, mixins],
  data() {
    return {
    }
  },
  computed: {
    imgList() {
      let img = this.project?.img;
      return img ? [img] : [];
    },
    activeImg() {
      return this.project.img;
    }
  },
  mounted() {
    if (this.isDesign) {
      this.ready = true;
    } else {
      utils.loadScript(() => {
        this.ready = true;
        this.$nextTick(() => {
          if (this.isDesignCtrl) {
            let {propsWidth, propsHeight, propsMimeType, propsQuality} = this;
            this.$set(this.project, 'width', propsWidth);
            this.$set(this.project, 'height', propsHeight);
            this.$set(this.project, 'mimeType', propsMimeType);
            this.$set(this.project, 'quality', propsQuality);
            this.$set(this.project, 'img', {
              id: dsf.uuid(8),
              layer: null
            });
          } else {
            this.$refs.config.open(() => {
              this.$set(this.project, 'img', {
                id: dsf.uuid(8),
                layer: null
              });
            });
          }
          this.$nextTick(() => {
            this.$dispatch('ready')
          });
        });
      });
    }
  },
  methods: {
    // 重设尺寸
    updateRect() {
      let {width, height} = this.project;
      this.konva?.setAttrs({width, height});
      if (this.activeImg) {
        this.activeImg.layer.setAttrs({width, height});
        this.$refs.main.resize();
      }
    },
    /********按钮*********/
    // 保存
    async save() {
      let {uploadHistoryList, project} = this;
      let file = await utils.exportByData(project);
      let {width, height, mimeType, quality, img} = project;
      project = {width, height, mimeType, quality, img: img.layer.toObject()};
      let json = {project, uploadHistoryList};
      this.$dispatch('save', {file, json});
    },
    // 导出
    async export() {
      let {width, height, mimeType, quality, img} = this.project;
      let zip = new window.JSZip();
      let project = {width, height, mimeType, quality, img: img.layer.toObject()};
      let base64 = await utils.layerToBase64(img.layer, width, height, {
        mimeType, quality
      }, true);
      zip.file(`效果图.png`, base64.split('base64,')[1], {base64: true});
      this.konva.add(img.layer);
      let uploadHistoryList = _.map(this.uploadHistoryList, 'relativePath');
      zip.file('config.json', JSON.stringify({project, uploadHistoryList}), {comment: '项目配置文件'});
      zip.generateAsync({type: "blob"}).then((blob) => {
        window.saveAs(blob, "ppt.zip");
      });
    },
    // 本地导入
    async importLocal(data) {
      if (!data) {
        data = await dsf.file.readJSON()
      }
      let {width: ow, height: oh, mimeType: om, quality: oq} = this.project;
      let {project: {width, height, mimeType, quality, img}, uploadHistoryList = []} = data;
      // 不允许修改配置
      if (this.propsDisabledConfig) {
        width = ow;
        height = oh;
        mimeType = om;
        quality = oq;
      }
      this.konva?.setAttrs({
        width,
        height
      });
      if (img) {
        await utils.loadAllImg(img);
        let layer = Konva.Node.create(img, 'layer');
        layer.attrs.width = width;
        layer.attrs.height = height;
        let bgDom = layer.findOne('#bg');
        bgDom.attrs.width = width;
        bgDom.attrs.height = height;
        img = {id: dsf.uuid(8), layer};
      } else {
        img = {id: dsf.uuid(8), layer: null};
      }
      this.project = {width, height, mimeType, quality, img};
      this.uploadHistoryList = _.map(uploadHistoryList, relativePath => {
        return {
          id: dsf.uuid(8),
          relativePath
        }
      });
    },
    // 远端导入
    importServe() {

    },
    // 项目配置
    setting() {
      this.$refs.config.open();
    },
    // 导出单页
    exportItem() {
      // 这里不需要此功能
    },
  }
});
</script>