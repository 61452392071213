<template>
  <div class="dsf-ppt-pattern">
    <div class="dsf-ppt-pattern-part1">
      <div class="left">
        <div class="dsf-ppt-pattern-img" :class="{'has-img': src}" :style="imgStyle">
          <div class="bt el-icon-upload" @click="showDialog = true">
            选择图片
          </div>
        </div>
        <el-popover
          v-show="src"
          class="dsf-ppt-pattern-preset"
          popper-class="dsf-ppt-pattern-preset-popover"
          placement="bottom"
          width="240"
          trigger="click"
          content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
          <span slot="reference">预设</span>
          <el-form ref="form" label-width="40px" size="mini">
            <el-form-item label="尺寸">
              <el-button-group>
                <el-button type="info" size="mini" @click="setSize('fill')">拉伸</el-button>
                <el-button type="info" size="mini" @click="setSize('cover')">铺满</el-button>
                <el-button type="info" size="mini" @click="setSize('contain')">适应</el-button>
                <el-button type="info" size="mini" @click="setSize('auto')">原大小</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item label="位置">
              <div class="bt-9">
                <el-button type="info" size="mini" @click="setPosition(1, 1)">左上</el-button>
                <el-button type="info" size="mini" @click="setPosition(2, 1)">中上</el-button>
                <el-button type="info" size="mini" @click="setPosition(3, 1)">右上</el-button>
                <el-button type="info" size="mini" @click="setPosition(1, 2)">左中</el-button>
                <el-button type="info" size="mini" @click="setPosition(2, 2)">居中</el-button>
                <el-button type="info" size="mini" @click="setPosition(3, 2)">右中</el-button>
                <el-button type="info" size="mini" @click="setPosition(1, 3)">左下</el-button>
                <el-button type="info" size="mini" @click="setPosition(2, 3)">中下</el-button>
                <el-button type="info" size="mini" @click="setPosition(3, 3)">右下</el-button>
              </div>
            </el-form-item>
          </el-form>
        </el-popover>
      </div>
      <dsf-ppt-angle-select class="right" v-model="rotation" @change="updateValue" />
    </div>
    <div class="dsf-ppt-pattern-part2">
      <el-form ref="form" label-width="70px" size="mini">
        <el-form-item label="重复选项">
          <el-select v-model="repeat" placeholder="请选择" @change="updateValue">
            <el-option label="不重复" value="no-repeat" />
            <el-option label="重复" value="repeat" />
            <el-option label="水平重复" value="repeat-x" />
            <el-option label="垂直重复" value="repeat-y" />
          </el-select>
        </el-form-item>
        <el-form-item label="缩放系数" class="scale">
          <el-input-number
            v-model="scaleX"
            :min="0"
            :step="0.001"
            :precision="3"
            :controls="false"
            size="mini"
            step-strictly
            @change="updateValue"
          />
          <el-input-number
            v-model="scaleY"
            :min="0"
            :step="0.001"
            :precision="3"
            :controls="false"
            size="mini"
            step-strictly
            @change="updateValue"
          />
        </el-form-item>
        <el-form-item label="旋转圆心" class="scale">
          <el-input-number
            v-model="x"
            :controls="false"
            size="mini"
            step-strictly
            @change="updateValue"
          />
          <el-input-number
            v-model="y"
            :controls="false"
            size="mini"
            step-strictly
            @change="updateValue"
          />
        </el-form-item>
        <el-form-item label="图片偏移" class="scale">
          <el-input-number
            v-model="offsetX"
            :controls="false"
            size="mini"
            step-strictly
            @change="updateValue"
          />
          <el-input-number
            v-model="offsetY"
            :controls="false"
            size="mini"
            step-strictly
            @change="updateValue"
          />
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      :visible.sync="showDialog"
      title="选择图片"
      width="800px"
      modal-append-to-body
      append-to-body>
      <dsf-ppt-img-library ref="library" style="height: 500px" />
      <span slot="footer" class="dialog-footer">
        <dsf-button size="small" type="plain" @click="showDialog = false">取 消</dsf-button>
        <dsf-button size="small" @click="confirm">确 定</dsf-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import utils from "../utils";

let srcCache = {}

export default {
  name: "DsfPptPattern",
  inject: ['$ppt'],
  props: {
    value: {
      type: Object,
      default: null
    },
    width: {
      type: Number,
      default() {
        return this.$ppt.project.width;
      }
    },
    height: {
      type: Number,
      default() {
        return this.$ppt.project.height;
      }
    },
    node: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showDialog: false,
      src: '',
      repeat: 'no-repeat',
      x: 0,
      y: 0,
      offsetX: 0,
      offsetY: 0,
      scaleX: 1,
      scaleY: 1,
      rotation: 0
    }
  },
  computed: {
    imgStyle() {
      if (!this.src) return {};
      return {
        'background-image': `url(${this.src})`
      };
    }
  },
  watch: {
    value() {
      this.init();
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let {src, x, y, offsetX, offsetY, scaleX, scaleY, rotation, repeat} = this.value;
      if (src?.src) src = src.src;
      let val = {src, x, y, offsetX, offsetY, scaleX, scaleY, rotation, repeat};
      let type = false;
      if (val) {
        for (let k in val) {
          if (this[k] !== val[k]) {
            type = true;
            break;
          }
        }
      } else {
        type = true;
      }
      if (type) {
        for (let k in val) {
          this[k] = val[k];
        }
      }
    },
    confirm() {
      let res = this.$refs.library.yes();
      if (!res?.relativePath) {
        dsf.layer.message('请选择一张图片', false);
        return;
      }
      this.showDialog = false;
      this.src = res.relativePath;
      this.setSize('cover', false);
      this.setPosition(2, 2);
    },
    async setSize(type, isUpdate = true) {
      let {src} = this;
      let W = this.node.width();
      let H = this.node.height();
      let img = srcCache[src]
      if (!srcCache[src]) {
        img = await utils.loadImage(src);
        if (img) {
          srcCache[src] = img;
        } else return;
      }
      let {width: w, height: h} = img;
      let k_x = W / w;
      let k_y = H / h;
      let scaleX = 1, scaleY = 1;
      if (type === 'fill') {
        scaleX = k_x;
        scaleY = k_y;
      } else if (type === 'contain') {
        scaleX = scaleY = k_x < k_y ? k_x : k_y;
      } else if (type === 'cover') {
        scaleX = scaleY = k_x < k_y ? k_y : k_x;
      }
      this.scaleX = scaleX;
      this.scaleY = scaleY;
      isUpdate && this.updateValue();
    },
    async setPosition(typeX, typeY) {
      let {src, scaleX, scaleY} = this;
      let W = this.node.width();
      let H = this.node.height();
      let img = srcCache[src]
      if (!srcCache[src]) {
        img = await utils.loadImage(src);
        if (img) {
          srcCache[src] = img;
        } else return;
      }
      let {width: w, height: h} = img;
      w *= scaleX;
      h *= scaleY;
      let x = W / 2, y = H / 2, offsetX, offsetY;
      if (typeX === 1) {
        offsetX = 0;
      } else if (typeX === 2) {
        offsetX = (W - w) / 2;
      } else {
        offsetX = W - w;
      }
      if (typeY === 1) {
        offsetY = 0;
      } else if (typeY === 2) {
        offsetY = (H - h) / 2;
      } else {
        offsetY = H - h;
      }
      this.x = x;
      this.y = y;
      this.offsetX = (x - offsetX) / scaleX;
      this.offsetY = (y - offsetY) / scaleY;
      this.updateValue();
    },
    async updateValue() {
      let {src, x, y, offsetX, offsetY, scaleX, scaleY, rotation, repeat} = this;
      if (!srcCache[src]) {
        let img = await utils.loadImage(src);
        if (img) {
          srcCache[src] = img;
        }
        let val = {src: img, x, y, offsetX, offsetY, scaleX, scaleY, rotation, repeat};
        let type = false;
        for (let k in val) {
          if (this.value[k] !== val[k]) {
            type = true;
            break;
          }
        }
        if (type) {
          this.$emit('input', val);
          this.$emit('change', val);
        }
      } else {
        let val = {src: srcCache[src], x, y, offsetX, offsetY, scaleX, scaleY, rotation, repeat};
        this.$emit('input', val);
        this.$emit('change', val);
      }
    }
  }
}
</script>