<template>
  <ppt-attr-panel title="字体">
    <div class="dsf-ppt-attrs-row">
      <dsf-ppt-fill :node="$attr.activeShape" />
    </div>
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">字号</div>
      <label class="dsf-ppt-attrs-row__column">
        <el-input :value="fontSize" :controls="false" size="mini" @input="$attr.setAttrs(vm, 'fontSize', $event)" />
      </label>
    </div>
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">行高</div>
      <label class="dsf-ppt-attrs-row__column">
        <el-input :value="lineHeight" :controls="false" size="mini" @input="$attr.setAttrs(vm, 'lineHeight', $event)" />
      </label>
    </div>
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">字体</div>
      <label class="dsf-ppt-attrs-row__column">
        <el-select :value="fontFamily" placeholder="请选择" size="mini" @input="$attr.setAttrsByStr(vm, 'fontFamily', $event)">
          <el-option v-for="item in fontFamilyList" :key="item" :label="item" :value="item" :style="{'font-family': item}" />
        </el-select>
      </label>
    </div>
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">样式</div>
      <label class="dsf-ppt-attrs-row__column">
        <el-select :value="fontStyle" placeholder="请选择" size="mini" @input="$attr.setAttrsByStr(vm, 'fontStyle', $event)">
          <el-option v-for="item in fontStyleList" :key="item.value" :label="item.text" :value="item.value" />
        </el-select>
      </label>
    </div>
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">装饰</div>
      <label class="dsf-ppt-attrs-row__column">
        <el-select :value="textDecoration" placeholder="请选择" size="mini" @input="$attr.setAttrsByStr(vm, 'textDecoration', $event)">
          <el-option v-for="item in textDecorationList" :key="item.value" :label="item.text" :value="item.value" />
        </el-select>
      </label>
    </div>
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">对齐</div>
      <label class="dsf-ppt-attrs-row__column">
        <el-select :value="align" placeholder="请选择" size="mini" @input="$attr.setAttrsByStr(vm, 'align', $event)">
          <el-option v-for="item in alignList" :key="item.value" :label="item.text" :value="item.value" />
        </el-select>
      </label>
      <label class="dsf-ppt-attrs-row__column">
        <el-select :value="verticalAlign" placeholder="请选择" size="mini" @input="$attr.setAttrsByStr(vm, 'verticalAlign', $event)">
          <el-option v-for="item in verticalAlignList" :key="item.value" :label="item.text" :value="item.value" />
        </el-select>
      </label>
    </div>
  </ppt-attr-panel>
</template>

<script>
import PptAttrPanel from './PptAttrPanel';

export default {
  name: "FontAttrsItem",
  inject: ['$attr'],
  components: {
    PptAttrPanel
  },
  data() {
    return {
      vm: this,
      fontFamilyList: [
        '宋体', '黑体', '微软雅黑', '微软正黑体', '新宋体', '新细明体',
        '细明体', '标楷体', '仿宋', '楷体',
        'Arial', 'PmingLiu', 'Impact', 'Georgia', 'Tahoma', 'Book Antiqua',
        'Century Gothic', 'Courier New', 'Times New Roman', 'Verdana'
      ],
      fontStyleList: [
        {text: '默认', value: 'normal'},
        {text: '加粗', value: 'bold'},
        {text: '斜体', value: 'italic'}
      ],
      textDecorationList: [
        {text: '默认', value: ''},
        {text: '贯穿线', value: 'line-through'},
        {text: '下划线', value: 'underline'}
      ],
      alignList: [
        {text: '水平居左', value: 'left'},
        {text: '水平居中', value: 'center'},
        {text: '水平居右', value: 'right'}
      ],
      verticalAlignList: [
        {text: '垂直居上', value: 'top'},
        {text: '垂直居中', value: 'middle'},
        {text: '垂直居下', value: 'bottom'}
      ],
      fontSize: 14,
      fontFamily: '',
      fontStyle: 'normal',
      textDecoration: '',
      align: 'left',
      verticalAlign: 'middle',
      lineHeight: 1
    }
  },
  methods: {
    updateAttrs() {
      this.$attr.getAttr([
        'fontSize',
        'fontFamily',
        'fontStyle',
        'textDecoration',
        'align',
        'verticalAlign',
        'lineHeight'
      ], this);
    }
  }
}
</script>