<template>
  <dsf-virtual-scroll v-once height="60vh" style="color: #333;">
    <p style="line-height: 4em;">
      <span style="font-size: 22px;"><strong>快捷键</strong></span>
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px;">
      <span style="font-size: 18px;"><strong><span style="background-color: rgb(242, 242, 242);">&nbsp;鼠标滚轮&nbsp;</span></strong>：垂直滚动画布</span>
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px;">
      <span style="font-size: 18px;"><strong><span style="font-size: 18px; background-color: rgb(242, 242, 242);">&nbsp;Shift </span>+&nbsp;<span style="font-size: 18px; background-color: rgb(242, 242, 242);">&nbsp;<span style="font-size: 18px; background-color: rgb(242, 242, 242);">鼠标滚轮&nbsp;</span></span></strong>：横向滚动画布</span>
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px;">
      <span style="font-size: 18px;"><strong><span style="font-size: 18px; background-color: rgb(242, 242, 242);">&nbsp;Alt&nbsp;</span> +&nbsp;<span style="font-size: 18px; background-color: rgb(242, 242, 242);">&nbsp;<span style="font-size: 18px; background-color: rgb(242, 242, 242);">鼠标滚轮&nbsp;</span></span></strong>：缩放画布</span>
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px;">
      <span style="font-size: 18px;"><strong><span style="font-size: 18px; background-color: rgb(242, 242, 242);">&nbsp;<span style="font-size: 18px; background-color: rgb(242, 242, 242);">Shift&nbsp;</span></span></strong>：显示刻度和辅助线</span>
    </p>
  </dsf-virtual-scroll>
</template>

<script>
export default {
  name: "PptHelp"
}
</script>