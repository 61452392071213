<template>
  <div class="dsf-ppt-fill">
    <dsf-ppt-tabs v-model="tabActive">
      <dsf-ppt-tab-pane name="color" title="纯色">
        <dsf-ppt-color-select v-model="color" @change="setBgColor" />
      </dsf-ppt-tab-pane>
      <dsf-ppt-tab-pane name="linear-gradient" title="线性渐变">
        <dsf-ppt-linear-gradual-select v-model="linear" @change="setLinearGradual" />
      </dsf-ppt-tab-pane>
      <dsf-ppt-tab-pane name="radial-gradient" title="径向渐变">
        <dsf-ppt-radial-gradual-select v-model="radial" @change="setRadialGradual" />
      </dsf-ppt-tab-pane>
      <dsf-ppt-tab-pane name="pattern" title="图案">
        <dsf-ppt-pattern v-model="pattern" :node="node" @change="setPattern" />
      </dsf-ppt-tab-pane>
    </dsf-ppt-tabs>
  </div>
</template>

<script>
import utils from "../../utils/utils";

// 填充
export default {
  name: "DsfPptFill",
  inject: ['$ppt'],
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tabActive: 'color',
      color: '#fff',
      linear: {
        angle: 0,
        point: [
          {length: 0, color: 'rgba(255,255,255,0)'},
          {length: 100, color: '#000'},
        ]
      },
      radial: {
        shape: 'circle',
        size: 'farthest-corner',
        position: [50, 50],
        point: [
          {length: 0, color: 'rgba(255,255,255,0)'},
          {length: 100, color: '#000'},
        ]
      },
      pattern: {
        src: null,
        repeat: 'no-repeat',
        x: 0,
        y: 0,
        offsetX: 0,
        offsetY: 0,
        scaleX: 1,
        scaleY: 1,
        rotation: 0
      }
    }
  },
  watch: {
    'node._id': {
      immediate: true,
      handler(to) {
        if (to) {
          this.init();
        }
      }
    },
    tabActive(to) {
      this.node.fillPriority(to);
      switch (to) {
      case 'color':
        this.setBgColor();
        break;
      case 'linear-gradient':
        this.setLinearGradual();
        break;
      case 'radial-gradient':
        this.setRadialGradual();
        break;
      default:
        this.setPattern();
      }
    }
  },
  methods: {
    init() {
      let {
        radius,
        fill: color,
        fillLinearGradientStartPointX: lsx,
        fillLinearGradientStartPointY: lsy,
        fillLinearGradientEndPointX: lex,
        fillLinearGradientEndPointY: ley,
        fillLinearGradientColorStops: lStops,
        fillRadialGradientStartPointX: rsx,
        fillRadialGradientStartPointY: rsy,
        fillRadialGradientEndPointX: rex,
        fillRadialGradientEndPointY: rey,
        fillRadialGradientStartRadius: rsr,
        fillRadialGradientEndRadius: rer,
        fillRadialGradientColorStops: rStops,
        fillPatternImage: src,
        fillPatternRotation: rotation,
        fillPatternRepeat: repeat,
        fillPatternX: x,
        fillPatternY: y,
        fillPatternOffsetX: offsetX,
        fillPatternOffsetY: offsetY,
        fillPatternScaleX: scaleX,
        fillPatternScaleY: scaleY
      } = this.node.attrs;
      let {className} = this.node;
      this.initColor(color);
      if (className == "Circle") {
        x !== undefined && (x += radius);
        y !== undefined && (y += radius);
        lsx !== undefined && (lsx += radius);
        lsy !== undefined && (lsy += radius);
        lex !== undefined && (lex += radius);
        ley !== undefined && (ley += radius);
        rsx !== undefined && (rsx += radius);
        rsy !== undefined && (rsy += radius);
        rex !== undefined && (rex += radius);
        rey !== undefined && (rey += radius);
      }
      this.initLinearGradual(lsx, lsy, lex, ley, lStops);
      this.initRadialGradual(rsx, rsy, rsr, rex, rey, rer, rStops);
      this.initPattern(src, rotation, repeat, x, y, offsetX, offsetY, scaleX, scaleY);
      this.tabActive = this.node.fillPriority();
    },
    /*********init**********/
    initColor(color = 'rgba(255,255,255,0)') {
      this.color = color;
      this.setBgColor(color);
    },
    initLinearGradual(sx = 0, sy = 0, ex = 0, ey = 1, stops = [0, 'rgba(255,255,255,0)', 1, '#000']) {
      let angle = utils.pointToAngle(sx, sy, ex, ey);
      let point = [];
      for (let i = 0, l = stops.length / 2; i < l; i++) {
        let l = stops[i * 2], c = stops[i * 2 + 1];
        point.push({
          length: Math.round(l * 100),
          color: c
        });
      }
      this.linear = {angle, point};
    },
    initRadialGradual(sx, sy, sr, ex, ey, er, stops = [0, 'rgba(255,255,255,0)', 1, '#000']) {
      let size = 'farthest-corner';
      let width = this.node.width();
      let height = this.node.height();
      if (sx == undefined) {
        sx = 50;
        sy = 50;
      } else {
        let horn1 = utils.getLengthByPoint(sx, sy, 0, 0);
        let horn2 = utils.getLengthByPoint(sx, sy, width, 0);
        let horn3 = utils.getLengthByPoint(sx, sy, width, height);
        let horn4 = utils.getLengthByPoint(sx, sy, 0, height);
        let side1 = utils.getLengthByPoint(sx, sy, sx, 0);
        let side2 = utils.getLengthByPoint(sx, sy, width, sy);
        let side3 = utils.getLengthByPoint(sx, sy, sx, height);
        let side4 = utils.getLengthByPoint(sx, sy, 0, sy);
        let farthestCorner = Math.max(horn1, horn2, horn3, horn4);
        let closestCorner = Math.min(horn1, horn2, horn3, horn4);
        let farthestSide = Math.max(side1, side2, side3, side4);
        let closestSide = Math.min(side1, side2, side3, side4);
        if (Math.abs(farthestCorner - er) < 2) {
          // 离圆心最远的角
          size = 'farthest-corner';
        } else if (Math.abs(closestCorner - er) < 2) {
          // 离圆心最近的角
          size = 'closest-corner';
        } else if (Math.abs(farthestSide - er) < 2) {
          // 离圆心最远的边
          size = 'farthest-side';
        } else if (Math.abs(closestSide - er) < 2) {
          // 离圆心最近的边
          size = 'closest-side';
        }
        sx = sx * 100 / width;
        sy = sy * 100 / height;
      }
      let point = [];
      for (let i = 0, l = stops.length / 2; i < l; i++) {
        let l = stops[i * 2], c = stops[i * 2 + 1];
        point.push({
          length: Math.round(l * 100),
          color: c
        });
      }
      this.radial = {
        shape: 'circle',
        position: [sx, sy],
        size,
        point
      };
    },
    initPattern(src = null, rotation = 0, repeat = 'no-repeat', x = 0, y = 0, offsetX = 0, offsetY = 0, scaleX = 1, scaleY = 1) {
      let pattern = {repeat, x, y, offsetX, offsetY, scaleX, scaleY, rotation};
      if (src?.src) {
        pattern.src = src.src;
      }
      this.pattern = pattern;
    },
    /********change*********/
    setBgColor(color = this.color) {
      this.node.fill(color);
    },
    setLinearGradual({angle, point} = this.linear) {
      let type = Math.floor(angle / 90);
      if (type === 1) angle = 180 - angle;
      else if (type === 2) angle -= 180;
      else if (type === 3) angle = 360 - angle;
      let width = this.node.width();
      let height = this.node.height();
      width /= 2;
      height /= 2;
      let sx, sy;
      if (angle === 0) {
        sx = 0;
        sy = height;
      } else if (angle === 90) {
        sx = -width;
        sy = 0;
      } else {
        angle = angle * Math.PI / 180;
        let t = Math.atan(width / height);
        let c = Math.sqrt(width * width + height * height);
        let b;
        if (angle < t) {
          b = Math.cos(t - angle) * c;
        } else {
          b = Math.cos(angle - t) * c;
        }
        sx = -Math.sin(angle) * b;
        sy = Math.cos(angle) * b;
      }
      if (type === 1 || type === 2) {
        sy *= -1;
      }
      if (type === 3 || type === 2) {
        sx *= -1;
      }
      let ex = sx * -1 + width, ey = sy * -1 + height;
      sx += width;
      sy += height;
      let stops = [];
      _.forEach(point, ({length, color}) => {
        stops.push(length / 100, color);
      });
      let { radius } = this.node.attrs;
      let { className } = this.node;
      if (className == "Circle") {
        sx -= radius;
        sy -= radius;
        ex -= radius;
        ey -= radius;
      }
      this.node.setAttrs({
        fillLinearGradientStartPoint: {x: Math.round(sx), y: Math.round(sy)},
        fillLinearGradientEndPoint: {x: Math.round(ex), y: Math.round(ey)},
        fillLinearGradientColorStops: stops
      });
    },
    setRadialGradual({size , position: [sx, sy], point} = this.radial) {
      let sr = 0, ex, ey, er, stops = [];
      let width = this.node.width();
      let height = this.node.height();
      sx = ex = width * sx / 100;
      sy = ey = height * sy / 100;
      _.forEach(point, ({length, color}) => {
        stops.push(length / 100, color);
      });
      let horn1 = utils.getLengthByPoint(sx, sy, 0, 0);
      let horn2 = utils.getLengthByPoint(sx, sy, width, 0);
      let horn3 = utils.getLengthByPoint(sx, sy, width, height);
      let horn4 = utils.getLengthByPoint(sx, sy, 0, height);
      let side1 = utils.getLengthByPoint(sx, sy, sx, 0);
      let side2 = utils.getLengthByPoint(sx, sy, width, sy);
      let side3 = utils.getLengthByPoint(sx, sy, sx, height);
      let side4 = utils.getLengthByPoint(sx, sy, 0, sy);
      switch (size) {
      // 离圆心最远的角
      case 'farthest-corner':
        er = Math.max(horn1, horn2, horn3, horn4);
        break;
      // 离圆心最近的角
      case 'closest-corner':
        er = Math.min(horn1, horn2, horn3, horn4);
        break;
      // 离圆心最远的边
      case 'farthest-side':
        er = Math.max(side1, side2, side3, side4);
        break;
      // 离圆心最近的边
      case 'closest-side':
        er = Math.min(side1, side2, side3, side4);
        break;
      }
      let { radius } = this.node.attrs;
      let { className } = this.node;
      if (className == "Circle") {
        sx -= radius;
        sy -= radius;
        ex -= radius;
        ey -= radius;
      }
      this.node.setAttrs({
        fillRadialGradientStartPoint: {x: sx, y: sy},
        fillRadialGradientEndPoint: {x: ex, y: ey},
        fillRadialGradientStartRadius: sr,
        fillRadialGradientEndRadius: er,
        fillRadialGradientColorStops: stops
      });
    },
    setPattern({src, rotation, repeat, x, y, offsetX, offsetY, scaleX, scaleY} = this.pattern) {
      if (src?.src) {
        this.node.fillPatternImage(src);
      }
      let { radius } = this.node.attrs;
      let { className } = this.node;
      if (className == "Circle") {
        x -= radius;
        y -= radius;
      }
      this.node.setAttrs({
        fillPatternRotation: rotation,
        fillPatternRepeat: repeat,
        fillPatternX: x,
        fillPatternY: y,
        fillPatternOffsetX: offsetX,
        fillPatternOffsetY: offsetY,
        fillPatternScaleX: scaleX,
        fillPatternScaleY: scaleY
      });
    }
  }
}
</script>