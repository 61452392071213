<template>
  <ppt-attr-panel title="背景填充">
    <dsf-ppt-fill :node="$attr.activeShape" />
  </ppt-attr-panel>
</template>

<script>
import PptAttrPanel from './PptAttrPanel';

export default {
  name: "BaseAttrsItem",
  inject: ['$attr'],
  components: {
    PptAttrPanel
  },
  methods: {
    updateAttrs() {}
  }
}
</script>