<template>
  <div class="dsf-ppt-tool-section">
    <div class="dsf-ppt-tool-title">背景属性</div>
    <dsf-ppt-fill v-if="bgDom" :node="bgDom" />
  </div>
</template>

<script>
export default {
  name: "PptBgAttr",
  inject: ['$ppt'],
  data() {
    return {
      bgDom: null
    }
  },
  computed: {
    layer() {
      return this.$ppt.activeImg.layer;
    },
    project() {
      return this.$ppt.project;
    }
  },
  watch: {
    'layer': {
      immediate: true,
      handler(to) {
        if (!to) return;
        this.bgDom = to.findOne('#bg');
      }
    },
  }
}
</script>