import { PptHeader, PptMain, PptFooter, PptConfig, PptMetadata } from '../components';

export default {
  name: "DsfPptSetting",
  ctrlCaption: "幻灯片设计",
  mixins: [$mixins.control],
  components: { PptHeader, PptMain, PptFooter, PptConfig, PptMetadata },
  provide() {
    return {
      $ppt: this
    }
  },
  props: {
    // 是否是设计器中的控件
    isDesignCtrl: {
      type: Boolean,
      default: false
    },
    propsWidth: {
      type: Number,
      default: 800
    },
    propsHeight: {
      type: Number,
      default: 600
    },
    propsMimeType: {
      type: String,
      default: 'image/jpeg'
    },
    propsQuality: {
      type: Number,
      default: 0.75
    },
    // 是否禁用配置框
    propsDisabledConfig: {
      type: Boolean,
      default: false
    },
    // 元数据/数据源
    propsMetaDataInfo: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      ready: false,
      konva: null,
      // 显示刻度
      showRuler: true,
      // 显示辅助线
      showMousePoint: true,
      // 当前激活的编辑器
      editor: null,
      // 上传图片历史
      uploadHistoryList: [],
      // 项目数据
      project: {},
      // 元数据
      metadataList: [],
      // 被拷贝的节点
      copyNode: null
    }
  },
  computed: {
    scale() {
      return this.activeImg ? this.$refs.main.scale : 100;
    }
  },
  created() {
    if (this.isDesign) {
      this.$emit('update:isDesignCtrl', true);
      this.$dispatch("design-height-change", "100%");
    } else {
      if (this.propsMetaDataInfo.length) {
        this.loadMetaDataGroup(this.propsMetaDataInfo);
      }
    }
  },
  methods: {
    /********按钮*********/
    // 项目配置
    setting() {
      this.$refs.config.open();
    },
    // 导出单页
    exportItem() {
      // 这里不需要此功能
    },
    /**********监听***********/
    // 插入值到文本编辑框中
    insetTextToEditor(text) {
      this.editor?.insertText(text);
    },
    /**********设计器***********/
    metaDataInfoChange() {
      const checkNodeList = this.propsMetaDataInfo;
      if (checkNodeList.length > 0) {
        let ctrlData = checkData(checkNodeList);
        let d = _.map(ctrlData, (data) => {
          return {
            id: data.type_value == "8" ? data.dsfa_rm_id : data.ID,
            type: data.type_value
          };
        });
        this.$emit('update:propsMetaDataInfo', d);
      }
    },
    //加载元数据组
    async loadMetaDataGroup(params) {
      try {
        //去除重复
        params = _.uniqBy(params, (v) => v.id);
        let result = await requestMetaDataGroupContent(this, params);
        let metadataList = [];
        _.each(result, (r) => {
          let list = _.filter(r.data || [], (m) => {
            if (m.type_value == 3) {
              return _.map(params, "id").indexOf(m.ID) >= 0;
            } else {
              return _.map(params, "id").indexOf(m.dsfa_rm_id) >= 0;
            }
          });
          metadataList.push(...list);
        });
        this.metadataList = _.map(metadataList, group => metadataFormat(group));
      } catch (ex) {
        dsf.httpError(ex);
      }
    },
  }
}

// 取出控件组数据 并去重
function checkData(data) {
  let arr = [];
  const getData = function (list) {
    list.forEach(function (row) {
      if (row.children && row.children.length > 0) {
        getData(row.children);
      } else {
        arr.push(row);
      }
    });
  };
  getData(data);
  return _.uniqBy(arr, "_id");
}

//获取元数据组
function requestMetaDataGroupContent(vm, params) {
  let httpList = [];
  for (let i = 0; i < params.length; i++) {
    if (params[i].type == "3") {
      httpList.push(vm.$http.get("/rm/list/meta", { namespace: params[i].id }));
    } else if (params[i].type == "8") {
      httpList.push(vm.$http.get("/dbsource/rmId", { rmId: params[i].id }));
    }
  }
  return vm.$http.all(httpList);
}

//将元数据添加到设计器的元数据组中
function metadataFormat(group) {
  let key = group.type_value == 8 ? group.dsfa_rm_id : group.ID;
  let result = {
    id: dsf.uuid(),
    key,
    title: group.name,
    type: group.type_value,
    items: []
  };
  _.each(group.metadata, (m) => {
    if (m.valueAttributes.length == 1) {
      let vab = m.valueAttributes[0];
      result.items.push({
        field: vab.type.value,
        name: m.name,
        id: dsf.uuid(),
        key: `${m.at.replace('.', '_')}.${m.code}`
      });
    } else {
      _.each(m.valueAttributes, vab => {
        result.items.push({
          field: vab.type.value,
          name: `${m.name} (${vab.name})`,
          id: dsf.uuid(),
          key: `${m.at.replace('.', '_')}.${m.code}`
        });
      });
    }
  });
  return result;
}