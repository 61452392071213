<template>
  <div class="dsf-ppt-tabs">
    <header>
      <div
        v-for="pane in paneList"
        :key="pane.name"
        class="dsf-ppt-tabs__name"
        :class="{active: pane.name === showPane}"
        @click="showPane = pane.name">
        {{ pane.title }}
      </div>
    </header>
    <main>
      <slot />
    </main>
  </div>
</template>

<script>
// tab面板
export default {
  name: "DsfPptTabs",
  provide() {
    return {
      tabs: this
    }
  },
  props: {
    value: {
      type: [String, Boolean, Number],
      default: null
    }
  },
  data() {
    return {
      paneList: []
    }
  },
  computed: {
    showPane: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  mounted() {
    let paneList = this.getPane();
    if (paneList.length) {
      let val = this.showPane;
      let pane = paneList.find(p => p.name === val);
      if (!pane) {
        this.showPane = paneList[0].name;
      }
    }
    this.paneList = paneList;
  },
  methods: {
    getPane() {
      let list = [];
      _.forEach(this.$slots.default, it => {
        if (it?.componentOptions.tag === 'dsf-ppt-tab-pane') {
          list.push(it.componentInstance);
        }
      });
      return list;
    }
  }
}
</script>