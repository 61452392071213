<template>
  <div v-show="show" class="dsf-ppt-metadata" :class="{moving: isDown}" :style="elStyle">
    <div class="title" title="可拖动窗口位置" @mousedown="mousedown">插入字段</div>
    <dsf-virtual-scroll class="content" height="0">
      <div v-for="group in $ppt.metadataList" :key="group.id" class="group">
        <div class="title">{{ group.title }}</div>
        <div class="content">
          <div v-for="item in group.items" :key="item.id" class="item" @click="itemClick(item)">
            <div class="ellipsis">{{ item.name }}</div>
            <div class="ellipsis">{{ item.key }}</div>
          </div>
        </div>
      </div>
    </dsf-virtual-scroll>
  </div>
</template>

<script>
export default {
  name: "PptMetadata",
  inject: ['$ppt'],
  data() {
    return {
      isDown: false,
      top: 0,
      left: 0,
    }
  },
  computed: {
    elStyle() {
      return {
        top: this.top + 'px',
        left: this.left + 'px'
      }
    },
    show() {
      return !!this.$ppt.editor;
    }
  },
  watch: {
    show(to) {
      to && this.$nextTick(() => this.resize());
    }
  },
  mounted() {
    window.addEventListener('resize', this.resize);
    window.addEventListener('mousemove', this.mousemove);
    window.addEventListener('mouseup', this.mouseup);
    document.body.appendChild(this.$el);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('mousemove', this.mousemove);
    window.removeEventListener('mouseup', this.mouseup);
    this.$el.remove();
  },
  methods: {
    itemClick(item) {
      this.$ppt.insetTextToEditor(`@[${item.key}]`);
    },
    resize() {
      if (!this.show) return;
      this._offsetX = this.left;
      this._offsetY = this.top;
      this.setPoint();
    },
    mousedown(e) {
      this.isDown = true;
      let {offsetLeft, offsetTop} = this.$el;
      let {screenX, screenY} = e;
      this._offsetX = this._SoffsetX = offsetLeft;
      this._offsetY = this._SoffsetY = offsetTop;
      this._screenX = screenX;
      this._screenY = screenY;
    },
    mousemove(e) {
      if (!this.isDown) return;
      let {screenX, screenY} = e;
      this._offsetX = this._SoffsetX + screenX - this._screenX;
      this._offsetY = this._SoffsetY + screenY - this._screenY;
      this.setPoint();
    },
    mouseup(e) {
      if (!this.isDown) return;
      let {screenX, screenY} = e;
      this._offsetX = this._SoffsetX + screenX - this._screenX;
      this._offsetY = this._SoffsetY + screenY - this._screenY;
      this.setPoint();
      this.isDown = false;
    },
    setPoint() {
      let {innerWidth, innerHeight} = window;
      let {offsetWidth, offsetHeight} = this.$el;
      let {_offsetX, _offsetY} = this;
      let top = _offsetY;
      let left = _offsetX;
      if (top < 70) {
        top = 70;
      } else if (top + offsetHeight > innerHeight - 10) {
        top = innerHeight - offsetHeight - 10;
      }
      if (left < 10) {
        left = 10;
      } else if (left + offsetWidth > innerWidth - 10) {
        left = innerWidth - offsetWidth - 10;
      }
      this.top = top;
      this.left = left;
    }
  }
}
</script>