<template>
  <div v-show="show" class="dsf-ppt-tab-panel">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'DsfPptTabPane',
  inject: {
    tabs: {
      type: Object,
      default: null
    }
  },
  props: {
    name: {
      type: [String, Boolean, Number],
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    show() {
      if (this.tabs) return this.tabs.showPane === this.name;
      return false;
    }
  }
}
</script>