<template>
  <div class="dsf-ppt-attrs" :class="{hide: hide}">
    <div class="title" @click="hide = !hide">
      <span>{{ title }}</span>
      <i class="iconfont" :class="[hide ? 'icon-weibiaoti34' : 'icon-xia1']"></i>
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "PptAttrPanel",
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      hide: false
    }
  }
}
</script>