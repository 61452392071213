import PptBgAttr from './PptBgAttr';
import PptITextAttr from './PptITextAttr';
import PptRichTextAttr from './PptRichTextAttr';
import PptImageAttr from './PptImageAttr';
import PptRectAttr from './PptRectAttr';
import PptCircleAttr from './PptCircleAttr';

export default {
  PptBgAttr,
  PptITextAttr,
  PptRichTextAttr,
  PptImageAttr,
  PptRectAttr,
  PptCircleAttr
};