<template>
  <div class="dsf-ppt-tool">
    <component v-if="attrsChange" :is="attrName" />
    <ppt-layer-tree />
  </div>
</template>

<script>
import attr from "./attr";
import PptLayerTree from "./PptLayerTree";

export default {
  name: "PptAttributes",
  inject: ['$pptMain'],
  components: {
    ...attr,
    PptLayerTree
  },
  data() {
    return {
      attrsChange: true
    }
  },
  computed: {
    attrName() {
      let shape = this.$pptMain.activeShape;
      if (!shape) return 'PptBgAttr';
      return `Ppt${shape.className}Attr`;
    }
  },
  watch: {
    '$pptMain.activeShape._id'() {
      this.attrsChange = false;
      this.$nextTick(() => {
        this.attrsChange = true;
      });
    }
  }
}
</script>