<template>
  <ppt-attr-panel title="描边">
    <div class="dsf-ppt-attrs-row">
      <dsf-ppt-color-select :value="stroke" @input="$attr.setAttrsByStr(vm, 'stroke', $event)" />
    </div>
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">线宽</div>
      <label class="dsf-ppt-attrs-row__column">
        <el-input :value="strokeWidth" size="mini" @input="$attr.setAttrs(vm, 'strokeWidth', $event)" />
      </label>
    </div>
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">边角</div>
      <label class="dsf-ppt-attrs-row__column">
        <el-select :value="lineJoin" placeholder="请选择" size="mini" @input="$attr.setAttrsByStr(vm, 'lineJoin', $event)">
          <el-option v-for="item in lineJoinList" :key="item.value" :label="item.text" :value="item.value" />
        </el-select>
      </label>
    </div>
  </ppt-attr-panel>
</template>

<script>
import PptAttrPanel from './PptAttrPanel';

export default {
  name: "StrokeAttrsItem",
  inject: ['$attr'],
  components: {
    PptAttrPanel
  },
  data() {
    return {
      vm: this,
      lineJoinList: [
        {text: '斜接（默认）', value: 'miter'},
        {text: '斜面', value: 'bevel'},
        {text: '圆形', value: 'round'}
      ],
      stroke: '#fff',
      strokeWidth: 0,
      lineJoin: 'miter'
    }
  },
  methods: {
    updateAttrs() {
      this.$attr.getAttr(['stroke', 'strokeWidth', 'lineJoin'], this);
    }
  }
}
</script>